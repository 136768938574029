import '../../styles/pages/fall-winter-2021/fighting-infernos-before-they-start.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import Vimeo from '@u-wave/react-vimeo';
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2021-stories/more-stories"
import { withPrefix } from 'gatsby';
import DownArrow from '../../components/downarrow/downarrow'
import { Preloader, Placeholder } from 'react-preloading-screen';
import { WhisperSpinner } from "react-spinners-kit";


import socialBanner from "../../images/fall-winter-2021/social-banners/fighting-infernos-before-they-start.jpg";

// import hero from "../../images/fall-winter-2021/fighting-infernos-before-they-start/hero.jpg";

import ankitaMohapatra from '../../images/fall-winter-2021/fighting-infernos-before-they-start/ankita-mohapatra.jpg'
import danielCurtis from '../../images/fall-winter-2021/fighting-infernos-before-they-start/daniel-curtis.jpg'
import rakeshMahto from '../../images/fall-winter-2021/fighting-infernos-before-they-start/rakesh-mahto.jpg'
import jidongHuang from '../../images/fall-winter-2021/fighting-infernos-before-they-start/jidong-huang.jpg'
import kennethFaller from '../../images/fall-winter-2021/fighting-infernos-before-they-start/kenneth-faller.jpg'

// import researchTeam from '../../images/fall-winter-2021/fighting-infernos-before-they-start/research-team.jpg'
import infographic from '../../images/fall-winter-2021/fighting-infernos-before-they-start/technology-infographic.jpg'
import phoneApp from '../../images/fall-winter-2021/fighting-infernos-before-they-start/phone-app.jpg'

import humanCenteredDesign from '../../images/fall-winter-2021/fighting-infernos-before-they-start/human-centered-design.jpg'
import homelessness from '../../images/fall-winter-2021/fighting-infernos-before-they-start/homelessness.jpg'




export default class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var pageTitle = 'Fighting Infernos Before They Start';
    var pageDesc = 'A faculty and student engineering team is using artificial intelligence to save property — and lives.';
    var slug = 'fighting-infernos-before-they-start';


    var heroStyles = {
      backgroundColor: '#222',
      // backgroundImage: `url(${hero})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      color: 'white',
      height: '100vh'
    }

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="fall-winter-2021">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={slug} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        <Preloader fadeDuration={500} style={{backgroundColor: '#111'}}>
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeIn delay-1s slower">
              <div className="title-top">
                <span className="fire">Fighting</span>&nbsp;
                <span className="burn">Infernos</span>&nbsp;
              </div>
              <div className="title-bottom">
                <span className="fire">Before</span>&nbsp;
                <span className="burn">They</span>&nbsp;
                <span className="fire">Start</span>
              </div>
            </h1>
            <p className="sub-heading animated fadeInDown delay-1s">A faculty and student engineering team is using artificial intelligence to save property — and lives.</p>
            <span className="animated fadeInDown delay-1s byline">By Debra Cano Ramos, Photos by Matt Gush</span>
            <DownArrow color="white" />
          </div>
          <div className="tint"></div>
          <video id="heroVideo" autoPlay="autoplay" loop muted="muted" >
            <source src={withPrefix('/video/fall-winter-2021/fire-hero.webm')} type="video/webm; codecs=vp9,vorbis" />
            <source src={withPrefix('/video/fall-winter-2021/fire-hero.mp4')} />
            <track kind="captions" srcLang="en" label="English" src={withPrefix('/video/fall-winter-2021/fire-hero.vtt')} />
          </video>
        </div>
        <div className="intro-area" id="intro">
          <article className="wrap small">

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">

          <p><span className="intro-text">After ash rained on her car while driving by a wildfire last year, Ankita Mohapatra had an idea — a big idea.</span></p>

          <p>As destructive wildfires raged across California and the region, the Cal State Fullerton assistant professor of computer engineering wanted to develop an early detection system to identify areas facing high wildfire risks using “smart” technology.</p>

          <p style={{paddingBottom: '35%'}}>Student and faculty researchers in the “Engineering Wildfire Mitigation” project are designing a wireless, solar-powered sensor hub that relays information to a central processing station for data analysis and generation of fire alerts. The idea is to use machine learning and artificial intelligence to identify and prevent hot spots.</p>
          </ScrollAnimation>

          </article>
        </div>
        <article className="wrap small">

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <figure className="align-wide align-wide-large" style={{marginTop: '-40%'}}>
            <Vimeo
              video="619182724"
              autoplay={false}
              responsive={true}
              showPortrait={false}
              showTitle={false}
              showByline={false}
              style={{ backgroundColor:'#000000' }}
            />
            {/* <img src={ankitaMohapatra} alt="Ankita Mohapatra" /> */}
            <figcaption>Ankita Mohapatra, assistant professor of computer engineering, with CSUF student Mark Robles and Santiago Canyon College student Julio Villegas</figcaption>
          </figure>
          </ScrollAnimation>
          
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>“Our goal is to save lives, minimize human displacement and loss of resources, support firefighting efforts and reduce carbon emissions,” says Mohapatra.</p>

          <p>Nearly one-third of California’s population lives in areas vulnerable to wildfires, with September and October historically being the worst months for fires. In 2021, the state is facing a record-breaking year of fires due to extreme heat, dry vegetation and climate change. More than 8,000 fires have ignited across the state, scorching millions of acres.</p>

          <figure className="align-right large align-outside">
            <img src={ankitaMohapatra} alt="Ankita Mohapatra" />
            <figcaption>Ankita Mohapatra, assistant professor of computer engineering</figcaption>
          </figure>

          <p>Research shows that early detection leads to better management and extinguishing flames faster, notes Mohapatra. This can result in saving lives, reducing threats to property, and curtailing curtailing health effects caused by smoke and toxins, such as carbon emissions.</p>
          </ScrollAnimation>

          {/* <p>Other team members are Kenneth John Faller II, associate professor of computer engineering; Rakesh Mahto, assistant professor of computer engineering; Jidong Huang, professor of electrical engineering; and Daniel B. Curtis, associate professor of chemistry and biochemistry, in the College of Natural Sciences and Mathematics.</p> */}

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p><strong>Other team members are:</strong></p>

          <div className="row clear">
            <div className="col-3">
              <figure>
                <img src={kennethFaller} alt="Kenneth John Faller II" />
                <figcaption>Kenneth John Faller II, associate professor of computer engineering</figcaption>
              </figure>
            </div>
            <div className="col-3">
              <figure>
                <img src={rakeshMahto} alt="Rakesh Mahto" />
                <figcaption>Rakesh Mahto, assistant professor of computer engineering</figcaption>
              </figure>
            </div>
            <div className="col-3">
              <figure>
                <img src={jidongHuang} alt="Jidong Huang" />
                <figcaption>Jidong Huang, professor of electrical engineering</figcaption>
              </figure>
            </div>
            <div className="col-3">
              <figure>
                <img src={danielCurtis} alt="Daniel B. Curtis" />
                <figcaption>Daniel B. Curtis, associate professor of chemistry and biochemistry</figcaption>
              </figure>
            </div>
          </div>

          <p>The wildfire project is one of three College of Engineering and Computer Science’s <a href="http://www.fullerton.edu/bigideas" target="_blank" rel="noreferrer">“Titans of Transformation: Big Ideas”</a> initiatives to offer real-life solutions to society’s most pressing issues. </p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <h2>Technology Fuels Fighting Fires</h2>
          <p>Researchers are developing rechargeable solar panels to power tiny sensor nodes that monitor changes in the physical environment, such as temperature, humidity, and fine air particles and gases, including carbon monoxide from smoke. The sensors would detect young fires or the probability of a flare-up. </p>

          <p>“A smart sensor node could be defined as a mini-computer that detects the environment around it and can determine the likelihood of a fire event,” explains Mohapatra, adding that a prototype is in development with input from local fire departments.</p>

          <p>Students are developing machine learning algorithms to determine the optimal locations for sensors in remote forests, local foothills and canyons — places where drought, dry conditions and vegetation add fuel for fires. The algorithm will best determine sensor locations using GPS coordinates.</p>
          </ScrollAnimation>

        </article>
        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
        <figure className="infographic-figure">
          <img src={infographic} alt="Infographic illustrating wildfire mitigation sensor hub" />
          <figcaption>
            <ol>
              <li>Solar-powered sensors monitor temperature, humidity and atmospheric changes.</li>
              <li>The sensors help detect young fires.</li>
              <li>Fire responders are quickly alerted to fires via a mobile app.</li>
              <li>The app helps responders take steps to suppress fires and coordinate evacuation efforts.</li>
            </ol>
          </figcaption>
        </figure>
        </ScrollAnimation>
        <article className="wrap small">

        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>“The sensors would read atmospheric quality and generate alarms when the values cross into thresholds that are characterized by fire,” says Mohapatra. “The sensors will provide vital data that can be assimilated with existing fire spread models to forecast the highest likelihood of a path to be taken by a fire.”</p>

          <p>The sensors will be powered by solar panels that can adapt to different environments, such as shade from trees, to provide uninterrupted power to the sensors day and night, points out Rakesh Mahto, assistant professor of computer engineering.</p>  

          <p>Aaron Nguyen, a senior computer engineering major, is learning about machine learning-based modeling to design solar panels that adapt to their location.</p>

          <p>“I hope that my work will make a significant contribution to the project and will be effective in combating wildfires,” says Nguyen, who is working under the mentorship of Mahto.</p> 

          <p>The researchers are using a novel transistor switch embedded in the solar panel to keep it powered. Mahto studied this technology as a doctoral student to power micro-autonomous drones.</p>

          <p>“These transistors enhance the capability of solar panels to reconfigure in real-time and are ideal for operating in conditions with varying lighting conditions,” Mahto says. “Changing light can lower the performance of solar panels, which in turn degrades the operability of sensors.”</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <h2>Communication App to Aid Emergency Response</h2>

          <p>Kenneth John Faller II, associate professor of computer engineering, is leading the design of a mobile app to support the project. The communication app will warn people about fires, help fire agencies and volunteers identify wildfire-prone areas, and coordinate evacuation efforts.</p>

          <p>The goal is to create a “smarter” system that will help in preemptive minimization of wildfire impact and improve public safety awareness through technology.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <figure className="align-right align-outside large">
            <img src={phoneApp} alt="Mobile app mockup" />
            {/* <figcaption>mobile app</figcaption> */}
          </figure>
          
          <p>“There is an urgency for supplemental technology to assist the current firefighting efforts and decrease the impact of wildfires, specifically in Southern California, where much of the state is highly susceptible,” Faller says.</p>
          
          </ScrollAnimation>
          
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>“Studies indicate that in communities impacted by wildfires, people of color and low-income residents are more vulnerable and find it disproportionately more difficult to recover from the effects of fire events.”</p>

          <p>Meanwhile, <a href="http://news.fullerton.edu/2019/11/climate-aerosol-particles/" target="_blank" rel="noreferrer">Daniel B. Curtis</a>, associate professor of chemistry and biochemistry, is working with the sensor board development team to add air particle sensors to the prototype to measure air quality, including the presence of soot or smoke particles.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <h2>Training the Next Generation of Engineers</h2>
          <p>Through this project, undergraduate students are gaining significant research and fellowship experiences.</p>

          <p>For Nguyen, the opportunity to learn about emerging technologies through this research project is equipping him for the workforce.</p>

          <p>“Dr. Mahto is teaching me about solar cells and giving me real-world experience to help produce results that will benefit the world,” Nguyen says. “My career goal is to be a part of an industry that is on the frontier of new technologies to help improve the quality of life for people.” <span className="end-mark" /></p>
          </ScrollAnimation>

        </article>
        <ScrollAnimation animateOnce={true} animateIn="fadeInUp">
        <div className="big-ideas">
          <div className="wrap small">
            <div className="row">
              <div className="col-12">
                <h2>‘Big Ideas’ for a Better Future</h2>
                <p><a href="http://www.fullerton.edu/bigideas" target="_blank" rel="noreferrer">“Titans of Transformation: Big Ideas”</a> are projects led by faculty committed to addressing societal challenges through transformative collaboration and innovation. </p>
                
                <p>“At its purest state, ‘Big Ideas’ is about creating a better future … tackling deep-seated problems, responding to our communities, establishing access to innovative curriculum, and preparing the next generation of culturally competent engineers and computer scientists,” says Susan Barua, dean of the College of Engineering and Computer Science.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <img src={homelessness} alt="" style={{width: '100%'}} />
                <h3>Digital Homelessness Initiative</h3>
                <p>The project will leverage technology-based solutions to serve the homeless, including using telehealth technology to provide mental health services and “digital identities” to help people securely keep track of such paperwork as Social Security and insurance cards. The initiative is directed by Anand Panangadan, assistant professor of computer science.</p>
              </div>
              <div className="col-6">
                <img src={humanCenteredDesign} alt="" style={{width: '100%'}} />
                <h3>Human-Centered Design Initiative</h3>
                <p>The initiative empowers engineering students with the ability to design and implement solutions for social problems in Orange County by collaborating with community organizations, nonprofits and governments. The project is led by Jin Woo Lee, assistant professor of mechanical engineering.</p>
              </div>
            </div>
            
            <p class="learn-more">
              Support Big Ideas Initiatives<br />
              <a href="http://fullerton.edu/bigideas" target="_blank" rel="noreferrer">fullerton.edu/bigideas</a>
            </p>
          </div>
        </div>
        </ScrollAnimation>
        <MoreStories slug={slug} />

        <Placeholder>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
              <WhisperSpinner size="30" frontColor="#ffae34" backColor="#cd4606" />
              <span style={{
                fontSize: '.7em',
                display: 'block', 
                marginTop: '30px', 
                color: '#ccc', 
                textTransform: 'uppercase',
                textAlign: 'center',
                letterSpacing: '5px'
              }}>
                Loading
              </span>
            </div>
          </Placeholder>
        </Preloader>
      </Layout>
    );
  }
} 